<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import { useCms } from '/~/composables/cms/use-cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'

const props = withDefaults(
  defineProps<{
    amount?: string | number
    shippingTax?: number
  }>(),
  {
    amount: 0,
    shippingTax: 0,
  }
)

const { calculatePointsToBurnOrder } = usePoints()
const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
const { showPointsToBurn } = useCms()

const pointsToBurn = computed(() =>
  calculatePointsToBurnOrder(Number(props.amount))
)
const formattedPointsToBurn = computed(() => formatPoints(pointsToBurn.value))
</script>

<template>
  <div class="flex items-center justify-center truncate">
    <span
      class="text-lg font-bold leading-none text-eonx-neutral-800 sm:text-2xl sm:leading-none"
      data-cy="calculation-amount"
    >
      {{ formatDollar(amount) }}
    </span>
    <template v-if="showPointsToBurn && isPurchaseOrderPointsAvailable">
      <span class="mx-[5px] text-base text-eonx-neutral-600 sm:mx-2.5">
        or use
      </span>
      <div class="font-bold text-eonx-neutral-800">
        <span class="text-lg sm:text-2xl">
          {{ formattedPointsToBurn }}
        </span>
        <span class="text-sm">PTS</span>
      </div>
    </template>
  </div>
</template>
